import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Video from '../components/Video';
import Seo from '../components/Seo';

const NavLink = (props) => {
  const { test, url, text } = props;
  if (!test) {
    return <Link to={url}>{text}</Link>;
  }
  return <span>{text}</span>;
};

const Videos = ({ pageContext }) => {
  const title = 'Videos';
  const slug = '/videos';
  const description = 'JLP videos.';

  const { group, index, pageCount } = pageContext;
  const first = index === 1;
  const last = index === pageCount;
  const previousUrl = index - 1 === 1 ? '' : (index - 1).toString();
  const nextUrl = (index + 1).toString();

  return (
    <div
      data-aos="fade-in"
      data-aos-delay="150"
    >
      <h1 className="page-header">{title}</h1>
      <div className="page-content">
        {group.length > 0 && (
          <div className="videos">
            {group.map(({ node }) => (
              <article
                className="row"
                key={node.prismicId}
                data-aos="fade-in"
                data-aos-offset="50"
              >
                <div className="columns small-12 medium-6 large-5">
                  <div className="flex-videox">
                    <Video video={node.data.video} />
                  </div>
                </div>
                <div className="columns small-12 medium-6 large-7">
                  <header>
                    <h3 className="title bumper warning">
                      {node.data.title.text}
                    </h3>
                    <span className="content">
                      <div dangerouslySetInnerHTML={{ __html: node.data.description.html }} />
                    </span>
                  </header>
                </div>
              </article>
            ))}
          </div>
        )}
      </div>
      <div className="pagination-wrapper">
        <ul className="pagination">
          <li className="previousLink">
            <NavLink
              test={first}
              url={`/videos/${previousUrl}`}
              text="Go to Previous Page"
            />
          </li>
          <li className="nextLink">
            <NavLink
              test={last}
              url={`/videos/${nextUrl}`}
              text="Go to Next Page"
            />
          </li>
        </ul>
      </div>
      {group.length === 0 && (
        <span className="results-msg">
          0 videos found.
        </span>
      )}
      <Seo
        slug={slug}
        title={title}
        description={description}
      />
    </div>
  );
};

NavLink.propTypes = {
  test: PropTypes.bool,
  url: PropTypes.string,
  text: PropTypes.string,
};

Videos.propTypes = {
  pageContext: PropTypes.object,
};

export default Videos;
